h1 {
    background-color: #7BAFD4;
    color: #002352;
    margin-top: 0;
    padding: 0 1rem;
    flex: 1,
}
.header {
    background-color: #7BAFD4;
}
.AEDicon {
    height: 100px;
    width: 100px;
}