*{
    box-sizing: border-box;
}

body {
    margin: 0;
}

.nav {
    background-color: #7BAFD4;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    padding: 0 1rem;
}

.site-title {
    font-size: 3rem;
    font-weight: bold;
}

.nav ul {
    padding: 0; 
    list-style: none;
    display: flex;
    gap: 1rem;
}

.nav a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: .25rem;
}

.nav li.active {
    background-color: #555;
}

.nav li.hover {
    background-color: #777;
}

.fosappicon {
    height: 100px;
    width: 100px;
}

.tab {
    font-size: 1.25rem;
}
